import style from "../../../styles/Status.module.scss";
import { StatusList } from "../../../utils/helpers";
import NextImage from "../../../components/NextElements/NextImage";
const Status = () => {
  return (
    <section className={style.statusWrap}>
      <div className={`${style.statusContainer} container`}>
        <div className={style.content}>
          <h2>What's your Order Status?</h2>
          <ul className={style.statusList}>
            {StatusList.map((card, key) => (
              <li className={style.ListItem} key={key}>
                <NextImage src={card.icon} className={style.statusListIcon} />
                <h3>{card.title}</h3>
                <p>{card.caption}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Status;

import React from "react";

const TextInput = (props) => {
  return (
    <div className="input">
      <label className="label">{props.label}</label>
      <input
        id="name"
        type={props?.type ? props?.type : "text"}
        required={props.required}
        value={props.value}
        placeholder={props.placeholder}
        onChange={(e) => {
          props.onChange(e);
        }}
      />
    </div>
  );
};

export default React.memo(TextInput);

import { useRouter } from 'next/router';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import images from '../../../assets/images';
import NextImage from '../../../components/NextElements/NextImage';
import { setFormValues } from '../../../stores/Auth/actions';
import style from '../../../styles/Banner.module.scss';
import TextInput from '../../Input/TextInput';
const Banner = () => {

    const dispatch = useDispatch();
    const router = useRouter();


    const [localValues, setLocalValues] = useState({
        tracking_id: '',
        postcode: ""
    })

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(setFormValues(localValues));
        router.push(`/track-delivery/${localValues.tracking_id}/${localValues.postcode}`)
    }

    return (
        <section className={style.bannerWrap}>
            <div className={`${style.bannerContainer} container`}>
                <div className={style.content}>
                    <div className={style.colInfo}>
                        <h2 className={style.colInfoHeader}>Track your Orders Easily</h2>
                        <p className={style.colInfoSubheader}>
                            Just enter your tracking number and postcode & it’s done.
                        </p>
                        <NextImage src={images.image_banner.src} className={style.colInfoImg} />
                    </div>
                    <div className={style.colForm}>
                        <h3 className={style.colFormHeader}>Enter Details</h3>
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <TextInput
                                placeholder="ER9J2TBSYNF5"
                                value={localValues.tracking_id}
                                required={true}
                                onChange={(e) => setLocalValues({ ...localValues, ['tracking_id']: e.target.value })}
                                label="Tracking Number"
                            />
                            <TextInput
                                placeholder="LE40GR"
                                label="Postcode"
                                value={localValues.postcode}
                                required={true}
                                onChange={(e) => setLocalValues({ ...localValues, ['postcode']: e.target.value })}
                            />
                            <button type="submit" className={style.colFormBtn}>
                                Track your delivery
                            </button>

                            <div className={style.formHelper}>
                                <h4>Can't find your order details?</h4>
                                <p>
                                    Please call us on 01254 268590 or Live chat with our team for any help or support.
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Banner
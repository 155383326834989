import Banner from "../components/Sections/Banner";
import Footer from "../components/Sections/Footer";
import Navbar from "../components/Sections/Navbar";
import Status from "../components/Sections/Status";
import FAQs from '../components/Sections/FAQs'
import NextHelmet from "../components/NextElements/NextHelmet";

const Home = () => {
  return (
    <>
      <NextHelmet title="Home" />
      <Navbar />
      <Banner />
      <Status />
      <FAQs />
      <Footer />
    </>
  );
};

export default Home;
